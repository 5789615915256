import React, { useState } from "react"
import SolutionDisplaySNPL from './../../assets/solution-1.png';
import SolutionDisplayZeroFee from './../../assets/solution-2.png';
import SolutionDisplayWallet from './../../assets/solution-3.png';

import './solution.scss';

const Solution = () => {

    const phoneDisplays = [SolutionDisplaySNPL, SolutionDisplayZeroFee, SolutionDisplayWallet];

    const [selectedSolution, setSelectedSolution] = useState(0);

    const [displayedPhone, setDisplayedPhone] = useState(phoneDisplays[selectedSolution])


    const solutions = [{
        title: 'Send Now, Pay Later Remittance (SNPL)',
        content: 'Send money home instantly, repay at interbank FX rates over a 3-month period using your bank card.',
    }, {
        title: 'Zero-Fee FX Card',
        content: 'Enjoy zero foreign exchange fees on all your purchases, anywhere in the world. Plus, earn cashback on domestic transactions and cashback when you\'re spending internationally. That\'s money back in your pocket, every time you swipe.',
    }, {
        title: 'Sinder Wallet and Send',
        content: 'Store and manage your funds, easily send remittances, and benefit from the lowest FX rates in the market.',
    }]



    return (
        <React.Fragment>
            <div className={"solution"}>
                <div className={"solution-left"}>
                    <span className={"solution-left-title"}>THE SOLUTION</span>
                    <span className={"solution-left-header"}>The Future of Remittance and Payments</span>
                    <p className={"solution-left-body"}>Sinder makes borrowing, sending, and spending money seamless with innovative, Shariah-compliant financial solutions:</p>
                    <div className={"solution-crumbs"}>
                        {solutions.map((solution, index) => (
                            <div key={index}>
                                <div className={"solution-crumbs-phone"}>
                                    <img className={"solution-crumbs-phone-image"} src={phoneDisplays[index]} alt={"phone-solution-display"} />
                                </div>
                                <div className={`solution-crumbs-layout ${index === selectedSolution ? 'solution-crumbs-green' : 'solution-crumbs-item '}`} onClick={() => { setSelectedSolution(index); setDisplayedPhone(phoneDisplays[index]) }}>
                                    <span className={"solution-crumbs-item-title"}>{solution.title}</span>
                                    <span className={"solution-crumbs-item-content"}>{solution.content}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={"solution-right"}>
                    <img className={"solution-right-phone"} src={displayedPhone} alt={"phone-image"} />
                </div>
            </div>
        </React.Fragment>
    )
}

export default Solution;
